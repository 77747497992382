<template>
  <div>
    <v-card :loading="loading" class="mx-auto my-12" max-width="40%">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img
        height="250"
        contain
        src="https://gba-images-singapore.s3.ap-southeast-1.amazonaws.com/web-assets/image-on-web/gba-logo2.png"
      ></v-img>

      <v-card-title>LOGIN</v-card-title>

      <v-col cols="12" v-if="informText != ''">
        <v-alert type="error">
          {{ informText }}
        </v-alert>
      </v-col>

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-text-field
          label="Account"
          v-model="account"
          @keyup.enter="login"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          label="Password"
          type="password"
          v-model="password"
          @keyup.enter="login"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn color="deep-purple lighten-2" text @click="login"> Login </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_8vBdk5PAd",
    userPoolWebClientId: "51e572vhkn38k7tuvsdst697b1",
    mandatorySignIn: true,
  },
});

export default {
  Amplify,
  name: "LoginComponent",
  data: () => ({
    loading: false,
    account: "",
    password: "",
    response: [],
    informText: "",
  }),

  created() {
    this.$root.$refs.login = this;
  },

  methods: {
    async login() {
      this.loading = true;
      this.informText = "";
      var checkLocal = localStorage.getItem("timeZone");
      console.log("checkLocal: ", checkLocal);
      await Auth.signIn(this.account, this.password)
        .then((response) => {
          console.log("response: ", response);

          this.$store.state.gbaToken =
            response.signInUserSession.accessToken.jwtToken;

          this.$store.state.gbaUser = response.username;

          //this.$cookies.set("timeZone", "America/Los_Angeles", -1);
          if (!checkLocal) {
            localStorage.setItem("timeZone", "America/Los_Angeles");
            this.$store.state.timeZoneSelected =
              localStorage.getItem("timeZone");
          }
          //window.domain = this.$cookies.get("domainSite");
          if (this.$store.state.gbaUser.indexOf("partner") > -1)
            this.$router.push("/partner-orders");
          else this.$router.push("/supplier-orders");
        })
        .catch((err) => {
          console.log("Error: ", err);
          this.informText = err;
        });

      if (this.$store.state.gbaToken != "" && this.$store.state.gbaUser != "") {
        this.$store.state.timeZoneSelected = localStorage.getItem("timeZone");
        await this.getUserRoles();
      }
      this.loading = false;
    },

    async getUserRoles() {
      //console.log("getUserRoles");
      await this.$root.$refs.app.checkToken();

      var response = await axios.get(
        window.domain + "users/" + this.$store.state.gbaUser
      );

      this.$store.state.gbaUserPermissions = response.data.data.permissions;
      //console.log("permission: ", this.$store.state.gbaUserPermissions);
    },
  },

  mounted() {
    if (this.$store.state.gbaToken != "" && this.$store.state.gbaUser != "") {
      this.$store.state.timeZoneSelected = localStorage.getItem("timeZone");
      if (this.$store.state.gbaUser.indexOf("partner") > -1)
        this.$router.push("/partner-orders");
      else this.$router.push("/supplier-orders");
    }
  },
};
</script>

<style lang="scss" scoped>
</style>