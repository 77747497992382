<template>
  <login />
</template>

<script>
// @ is an alias to /src
import login from "@/components/Login/c-login.vue";

export default {
  name: "LoginView",
  components: {
    login,
  },
};
</script>
